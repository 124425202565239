<template>
    <div class="dashboard" :style="`--var-accent-color: ${ clr.accent_clr };--var-timer-color: ${ clr.timer_clr };--var-btn-color: ${clr.btn_clr};--var-btn-txt-color: ${clr.btn_txt_clr}; --var-block-bg-color: ${clr.block_bg_clr}`">
        <div class="client_card">
            <div class="card_header" v-if="nav_tab == 'plan'">
                <nav>
                    <img class="logo_img" :src="require('@/assets/images/logo.svg')" alt="logo">
                    <h4>Dashboard</h4>
                </nav>
                <div class="user_box">
                    <img :src="require('@/assets/images/male-female.png')">
                    <div class="user_info">
                        <h5>Brian Devlin</h5>
                    </div>
                </div>
            </div>
            <div class="card_header" v-else>
                <nav>
                    <button type="button" class="back_btn" @click="nav_tab = 'plan'" v-if="!isWorkout && nav_tab == 'workouts'"><i class="fas fa-chevron-left"></i></button>
                    <button type="button" class="back_btn" @click="nav_tab = 'workouts'" v-if="nav_tab == 'details'"><i class="fas fa-chevron-left"></i></button>
                    <button type="button" class="back_btn" @click="nav_tab = 'details'" v-if="nav_tab == 'complete'"><i class="fas fa-chevron-left"></i></button>
                    <button type="button" class="back_btn" @click="nav_tab = 'complete'" v-if="nav_tab == 'logged'"><i class="fas fa-chevron-left"></i></button>
                    <h4>Dashboard</h4>
                </nav>
                <div class="user_box">
                    <img class="logo_img" :src="require('@/assets/images/logo.svg')" alt="logo">
                </div>
            </div>
        </div>
        <div class="dashboard_content py-2" :style="`pointer-events: ${timer_lg ? 'none' : 'all'};`">
            <div class="dashboard_container">
                <div class="exercise_list" v-if="nav_tab == 'plan'">
                    <div class="exercise_item">
                        <div class="instruction pt-0">
                            <h4 class="headline">Plan Name</h4>
                            <p>Accusamus est quis impedit voluptates esse aliquid harum iusto, atque obcaecati distinctio aperiam. Voluptatibus provident eos, recusandae itaque ad ullam!</p>
                            <div class="quick_guide">
                                <ul>
                                    <li>
                                        <h5>30</h5>
                                        <h6>Workouts</h6>
                                    </li>
                                    <li>
                                        <h5>120</h5>
                                        <h6>Blocks</h6>
                                    </li>
                                    <li>
                                        <h5>6 Weeks</h5>
                                        <h6>Duration</h6>
                                    </li>
                                </ul>
                                <button type="button" class="start_btn" @click="startPlan">{{completedWorkout > 0 ? 'Resume Program' : 'Start Program'}}</button>
                            </div>
                        </div>
                        <div class="week_list">
                            <div class="day_guide">
                                <label><span class="active m-0"></span>Work Day</label>
                                <label><span class="m-0"></span>Rest Day</label>
                            </div>
                            <ul>
                                <li v-for="(week, w) in weeks" :key="w" :class="{'completed': completedWorkout >= w + 1}">
                                    <span class="bullet_icon">{{w + 1}}</span>
                                    <div class="exercise_info">
                                        <h4>Week {{w + 1}} <span>{{week.timeline}}</span></h4>
                                        <div class="week_days_list">
                                            <label v-for="(item, i) in week.items" :key="i" @click="item.workday ? startPlan() : ''">
                                                <div class="item_wpr" :class="{'present_day' : ((new Date().getDay() - 1) == i) && completedWorkout == w}">
                                                    <span class="day_stat" :class="{'active' : item.workday}"></span>
                                                    <span class="weekdays">{{item.day}}</span>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="exercise_list" v-if="nav_tab == 'workouts'">
                    <div class="exercise_item">
                        <div class="instruction pt-0">
                            <h4 class="headline">Workout Name</h4>
                            <p>Accusamus est quis impedit voluptates esse aliquid harum iusto, atque obcaecati distinctio aperiam. Voluptatibus provident eos, recusandae itaque ad ullam!</p>
                            <div class="quick_guide">
                                <ul>
                                    <li>
                                        <h5>16</h5>
                                        <h6>Workouts</h6>
                                    </li>
                                    <li>
                                        <h5>40</h5>
                                        <h6>Sets</h6>
                                    </li>
                                    <li>
                                        <h5>45 Min</h5>
                                        <h6>Duration</h6>
                                    </li>
                                </ul>
                                <button type="button" class="start_btn" @click="start_screen = true;">{{workout_started ? 'Workout Started' : 'Start Workout'}}</button>
                            </div>
                        </div>
                        <swiper :pagination="{clickable: true}" :navigation="true" class="plan_wrapper">
                            <swiper-slide v-for="(workout, w) in plan" :key="w">
                                <div class="block_title">{{workout.title}} &nbsp;</div>
                                <div class="plan_list">
                                    <div class="block_type">Perform as a {{workout.type}}</div>
                                    <div class="plan_info">{{workout.type == 'circuit' ? '1 round | 30 sec rest interval' : '&nbsp;'}}</div>
                                    <ul>
                                        <li v-for="(exercise, e) in workout.items" :key="e" @click="exerciseDetails(w, e)">
                                            <div class="video_area">
                                                <div class="video_wpr">
                                                    <div class="video-overlay"></div>
                                                    <iframe :src="exercise.link" class="loom-wrapper" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                                                </div>
                                            </div>
                                            <div class="info_wpr">
                                                <h4>{{exercise.name}}</h4>
                                                <h6 v-if="workout.type == 'circuit'">
                                                    <span v-if="workout.time"><a v-if="workout.time_min">{{workout.time_min}} min</a> <a v-if="workout.time_sec">{{workout.time_sec}} sec</a></span>
                                                    <span v-else>25 Reps</span>
                                                    <span>30% of {{ max_reps }} {{ weight_unit.value }}</span>
                                                </h6>
                                                <h6 v-else><span>3 sets</span><span>8 reps</span><span>10 kgs</span><span>2 min rest</span></h6>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </swiper-slide>
                        </swiper>
                    </div>
                </div>

                <div class="exercise_details" v-if="nav_tab == 'details'">
                    <div class="instruction pt-0">
                        <h4 class="headline">{{plan[workout].items[exercise_item - 1].name}}</h4>
                        <h5>Exercise description text goes here</h5>
                        <div class="video_wpr">
                            <div class="video-overlay"></div>
                            <iframe :src="plan[workout].items[exercise_item - 1].link" class="loom-wrapper" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                        </div>
                    </div>
                    <div class="instruction">
                        <h4 class="headline">Instructions</h4>
                        <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed labore provident magni, quisquam cum magnam eum doloremque ut eaque doloribus!<br/><br/>
                            Accusamus est quis impedit voluptates esse aliquid harum iusto, atque obcaecati distinctio aperiam. Voluptatibus provident eos, recusandae itaque ad ullam!
                        </p>
                    </div>
                    <div class="activity" v-if="plan[workout].type == 'circuit'" ref="calc_section">
                        <div class="instruction pt-0">
                            <h4 class="headline">
                                Exercise {{exercise_item}} in 1 rounds circuit
                                <button type="button" class="calc_btn" :class="!max_reps ? 'blink' : ''" v-if="plan[this.workout].type == 'circuit'" @click="foo">{{max_reps ? 'Edit' : 'Calculate'}}</button>
                            </h4>
                        </div>
                        <table>
                            <thead>
                                <tr>
                                    <th v-if="plan[workout].time">Time</th>
                                    <th v-else>Reps</th>
                                    <th>Tempo</th>
                                    <th>Load</th>
                                    <th>Rest</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td v-if="plan[workout].time">
                                        <input class="no_spinner" type="number" name="" :placeholder="plan[workout].time_min" disabled>:
                                        <input class="no_spinner" type="number" name="" :placeholder="plan[workout].time_sec" disabled>
                                    </td>
                                    <td v-else><input type="number" name="" placeholder="25"></td>
                                    <td>3131</td>
                                    <td>
                                        <div class="flex">
                                            <input class="no_spinner" type="number" name="" :placeholder="calculate_load">{{ weight_unit.value }}
                                        </div>
                                    </td>
                                    <td>
                                        <div class="flex">
                                            <input class="no_spinner" type="number" name="" placeholder="00">:
                                            <input class="no_spinner" type="number" name="" placeholder="30">
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="activity" v-else>
                        <table>
                            <thead>
                                <tr>
                                    <th class="set">Set#</th>
                                    <th>Reps</th>
                                    <th>Tempo</th>
                                    <th>Load</th>
                                    <th>Rest</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="set">1</td>
                                    <td><input type="number" name="" placeholder="8"></td>
                                    <td>3131</td>
                                    <td>
                                        <div class="flex">
                                            <input class="no_spinner" type="number" name="" placeholder="10">kgs
                                        </div>
                                    </td>
                                    <td>
                                        <div class="flex">
                                            <input class="no_spinner" type="number" name="" placeholder="02">:
                                            <input class="no_spinner" type="number" name="" placeholder="00">
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="set">2</td>
                                    <td><input type="number" name="" placeholder="8"></td>
                                    <td>3131</td>
                                    <td>
                                        <div class="flex">
                                            <input class="no_spinner" type="number" name="" placeholder="10">kgs
                                        </div>
                                    </td>
                                    <td>
                                        <div class="flex">
                                            <input class="no_spinner" type="number" name="" placeholder="02">:
                                            <input class="no_spinner" type="number" name="" placeholder="00">
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="set">3</td>
                                    <td><input type="number" name="" placeholder="8"></td>
                                    <td>3131</td>
                                    <td>
                                        <div class="flex">
                                            <input class="no_spinner" type="number" name="" placeholder="10">kgs
                                        </div>
                                    </td>
                                    <td>
                                        <div class="flex">
                                            <input class="no_spinner" type="number" name="" placeholder="02">:
                                            <input class="no_spinner" type="number" name="" placeholder="00">
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="action_wpr">
                        <button type="button" class="incomplete_btn" @click="incomplete = true;">Mark Incomplete</button>
                        <button type="button" class="complete_btn" @click="nextExercise" v-if="plan[workout].type == 'progression' && exercise_item < 3">Done</button>
                        <button type="button" class="complete_btn" @click="nextWorkout" v-if="exercise_item == 3 && has_next_btn">Next</button>
                        <div class="incomplete_sec" v-if="incomplete">
                            <div class="setting_wpr">
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label mb-2">Reason for non-completion</label>
                                        <div class="field_wpr">
                                            <textarea name="" id="" cols="30" rows="10" placeholder="Type your reason here.."></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="action_wpr">
                                <button type="button" class="cancel_rsn" @click="incomplete = false;">Cancel</button>
                                <button type="button" class="save_rsn">Save</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="exercise_list" v-if="nav_tab == 'complete'">
                    <div class="exercise_item">
                        <div class="instruction pt-0">
                            <h4 class="headline">Great work Brian!</h4>
                            <p>Please log your workout and leave any rating, feedback, questions, or concerns for your coach below.</p>
                            <!-- <div class="quick_guide">
                                <ul>
                                    <li>
                                        <h5>30</h5>
                                        <h6>Workouts</h6>
                                    </li>
                                    <li>
                                        <h5>120</h5>
                                        <h6>Blocks</h6>
                                    </li>
                                    <li>
                                        <h5>6 Weeks</h5>
                                        <h6>Duration</h6>
                                    </li>
                                </ul>
                            </div> -->
                        </div>
                        <div class="workout_completion">
                            <div class="finish_timer">
                                <h2>Workout Time</h2>
                                <div class="total_timer">
                                    <label><a>00</a><span>hrs</span></label>:
                                    <label><a>{{total_min_left < 10 ? `0${total_min_left}` : total_min_left}}</a><span>min</span></label>:
                                    <label><a>{{total_sec_left < 10 ? `0${total_sec_left}` : total_sec_left}}</a><span>sec</span></label>
                                </div>
                            </div>
                            <div class="ratings">
                                <ul>
                                    <li>
                                        Difficulty
                                        <span>
                                            <input type="radio" name="dif"/>
                                            <input type="radio" name="dif"/>
                                            <input type="radio" name="dif"/>
                                            <input type="radio" name="dif"/>
                                            <input type="radio" name="dif"/>
                                        </span>
                                    </li>
                                    <li>
                                        Enjoyment
                                        <span>
                                            <input type="radio" name="enj"/>
                                            <input type="radio" name="enj"/>
                                            <input type="radio" name="enj"/>
                                            <input type="radio" name="enj"/>
                                            <input type="radio" name="enj"/>
                                        </span>
                                    </li>
                                    <li>
                                        Energy
                                        <span>
                                            <input type="radio" name="enr"/>
                                            <input type="radio" name="enr"/>
                                            <input type="radio" name="enr"/>
                                            <input type="radio" name="enr"/>
                                            <input type="radio" name="enr"/>
                                        </span>
                                    </li>
                                    <li>
                                        Mood
                                        <span>
                                            <input type="radio" name="mod"/>
                                            <input type="radio" name="mod"/>
                                            <input type="radio" name="mod"/>
                                            <input type="radio" name="mod"/>
                                            <input type="radio" name="mod"/>
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <div class="setting_wpr">
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">Notes:</label>
                                        <div class="field_wpr">
                                            <textarea name="" id="" cols="30" rows="10" placeholder="Type your notes here.."></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button type="button" class="start_btn" @click="nav_tab = 'logged';">Log Workout</button>
                        </div>
                    </div>
                </div>

                <div class="exercise_list" v-if="nav_tab == 'logged'">
                    <div class="exercise_item">
                        <div class="instruction pt-0">
                            <h4 class="headline">Awesome Brian!</h4>
                        </div>
                        <div class="workout_logged">
                            <div class="success"><i class="fas fa-check-circle"></i></div>
                            <h4>Your Workout Has Been Logged!</h4>
                            <p>Next workout is Wednesday April 20th</p>
                            <div class="workout_name">"Lower Body Mania"</div>
                            <div class="reminder">
                                <label for="text_reminder" class="switch_option capsule_btn border-0 m-0">
                                    <h5>Text Reminder?</h5>
                                    <input type="radio" id="text_reminder" :value="1" v-model="reminder_type" hidden>
                                    <div><span></span></div>
                                </label>
                                <label for="mail_reminder" class="switch_option capsule_btn border-0 m-0">
                                    <h5>Email Reminder?</h5>
                                    <input type="radio" id="mail_reminder" :value="2" v-model="reminder_type" hidden>
                                    <div><span></span></div>
                                </label>
                            </div>
                            <div class="setting_wpr time_line">
                                <div class="form_grp col-3">
                                    <div class="group_item">
                                        <div class="field_wpr">
                                            <input type="number" min="0" max="12" v-model="reminder_hr">
                                        </div>
                                    </div>
                                    <div class="group_item">
                                        <div class="field_wpr">
                                            <input type="number" min="0" max="59" v-model="reminder_min">
                                        </div>
                                    </div>
                                    <div class="group_item">
                                        <div class="field_wpr">
                                            <Multiselect v-model="reminder_clock.value" v-bind="reminder_clock"></Multiselect>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="reminder_day">
                                <label for="day_before">
                                    <input type="radio" id="day_before" :value="1" v-model="reminder_day" hidden>
                                    <span></span>The day before your workout
                                </label>
                                <label for="very_day">
                                    <input type="radio" id="very_day" :value="2" v-model="reminder_day" hidden>
                                    <span></span>The day of your workout
                                </label>
                            </div>
                            <button type="button" class="start_btn" @click="workout_save = true; has_redirect = true;">{{workout_save ? 'Saved' : 'Save Settings'}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <footer class="dashboard_footer">
            <ul>
                <li class="pointer"><a><i class="fab fa-facebook-f"></i></a></li>
                <li class="pointer"><a><i class="fab fa-twitter"></i></a></li>
                <li class="pointer"><a><i class="fab fa-instagram"></i></a></li>
            </ul>
            <h4>© 2022 - Onboard Me</h4>
            <p><router-link to="/">Terms</router-link><router-link to="/">Privacy</router-link> Powered By Onboard Me</p>
        </footer> -->

        <div class="card_details" :class="{active : card_details}">
            <div class="deatils_header">
                <button type="button" class="back_btn" @click="card_details = false"><i class="fas fa-chevron-left"></i></button>
                Details
            </div>
            <div class="exercise_details">
                <div class="instruction pt-0">
                    <h4 class="headline">{{plan[blockNumb].items[exerciseNumb].name}}</h4>
                    <h5>Exercise description text goes here</h5>
                    <div class="video_wpr">
                        <div class="video-overlay"></div>
                        <iframe :src="plan[blockNumb].items[exerciseNumb].link" class="loom-wrapper" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                    </div>
                </div>
                <div class="instruction">
                    <h4 class="headline">Instructions</h4>
                    <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed labore provident magni, quisquam cum magnam eum doloremque ut eaque doloribus!<br/><br/>
                        Accusamus est quis impedit voluptates esse aliquid harum iusto, atque obcaecati distinctio aperiam. Voluptatibus provident eos, recusandae itaque ad ullam!
                    </p>
                </div>
                <div class="activity" v-if="plan[blockNumb].type == 'circuit'">
                    <div class="instruction pt-0">
                        <h4 class="headline">
                            Exercise Timeline
                            <button type="button" class="calc_btn" :class="!max_reps ? 'blink' : ''" v-if="plan[blockNumb].type == 'circuit'" @click="foo">{{max_reps ? 'Edit' : 'Calculate'}}</button>
                        </h4>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th v-if="plan[blockNumb].time">Time</th>
                                <th v-else>Reps</th>
                                <th>Tempo</th>
                                <th>Load</th>
                                <th>Rest</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td v-if="plan[blockNumb].time">01:00</td>
                                <td v-else>8</td>
                                <td>3131</td>
                                <td>30% of 1RM</td>
                                <td>00:30</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="activity" v-else>
                    <table>
                        <thead>
                            <tr>
                                <th class="set">Set#</th>
                                <th>Reps</th>
                                <th>Tempo</th>
                                <th>Load</th>
                                <th>Rest</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="set">1</td>
                                <td>8</td>
                                <td>3131</td>
                                <td>10 kgs</td>
                                <td>02:00</td>
                            </tr>
                            <tr>
                                <td class="set">2</td>
                                <td>8</td>
                                <td>3131</td>
                                <td>10 kgs</td>
                                <td>02:00</td>
                            </tr>
                            <tr>
                                <td class="set">3</td>
                                <td>8</td>
                                <td>3131</td>
                                <td>10 kgs</td>
                                <td>02:00</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        
        <div class="start_screen timer lg" :class="{active : has_redirect}" >
            <div class="welcome_title">
                <div class="mb-3">
                    <div class="success"><i class="fas fa-check-circle"></i></div>
                    You Have Completed The Workout Sucessfully!
                </div>
                <div class="title_sm mb-4">Yor will be redirected back to plan in</div>
            </div>
            <div class="rest_alert">
                <div class="score_circle">
                    <div class="inner_circle">
                        <div class="score">
                            <div>{{redirect_sec_left}}</div>
                        </div>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="160" height="160">
                        <circle cx="80" cy="80" r="77" stroke-linecap="round" fill="none" stroke-width="6px" :stroke="redirect_sec_left ? 'var(--var-timer-color)' : '#2f7eed'" :stroke-dasharray="484" :stroke-dashoffset="(484 / 60) * (redirect_sec_left * 20)"/>
                    </svg>
                </div>
            </div>
        </div>
        
        <div class="start_screen timer lg" :class="{active : start_screen}" >
            <ul class="block_stat">
                <li v-for="(block, b) in plan" :key="b" :class="{'active' : workout > b, 'blink' : workout == b}">{{ block.title }}</li>
            </ul>
            <div class="welcome_title">
                <div v-if="workout > 0" class="mb-2">
                    <div class="success"><i class="fas fa-check-circle"></i></div>
                    Block {{ workout}} is Complete!
                </div>
                <div class="title_sm mb-4">Block {{ workout + 1}} of {{ plan.length }} is starting in</div>
            </div>
            <div class="rest_alert">
                <div class="score_circle" :class="{pointer : !start_min_left && !start_sec_left}">
                    <div class="inner_circle">
                        <div class="score" v-if="start_min_left || start_sec_left">
                            <div>{{start_min_left < 10 ? `0${start_min_left}` : start_min_left}}:{{start_sec_left < 10 ? `0${start_sec_left}` : start_sec_left}}</div>
                            <button class="play_pause_btn" @click="stopStartCountdown"><i :class="`fas fa-${stoppedTimer ?  'play' : 'pause' }`"></i></button>
                        </div>
                        <div class="score go" v-else @click="startBtn">
                            Go
                            <span class="hand_icon"><i class="far fa-hand-point-up"></i></span>
                        </div>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="160" height="160">
                        <circle cx="80" cy="80" r="77" stroke-linecap="round" fill="none" stroke-width="6px" :stroke="start_sec_left ? 'var(--var-timer-color)' : '#2f7eed'" :stroke-dasharray="484" :stroke-dashoffset="(484 / 60) * (start_sec_left * 4)"/>
                    </svg>
                </div>
            </div>
            <div class="block_note" v-if="plan[workout].type == 'circuit'">
                This is a 1-round, 3 exercises {{plan[workout].title}} circuit with a 1-minute work to 30-seconds rest interval.
            </div>
            <div class="block_note" v-if="plan[workout].type == 'progression'">
                Complete all sets of each exercise before moving to the next exercise.
            </div>
            <div class="final_note" v-if="(workout + 1) == plan.length">
                After Completion of this block your workout is over and you can give feedback to your coach.
            </div>
        </div>

        <div class="timer" :class="{active : workout_started, lg : toggle_box}" >
            <button type="button" class="toggle_box" @click="toggle_box = !toggle_box;"><i class="fas fa-bars"></i></button>
            <div class="alert" v-if="plan[this.workout].type == 'circuit' && !timer_lg && !toggle_box && !max_reps" @click="goTo('calc_section')">Click on calculate to add your load <i class="fas fa-long-arrow-alt-right"></i></div>
            <Transition duration="550" name="nested">
                <div class="exercise_details" v-if="toggle_box">
                    <div class="instruction pt-2">
                        <label v-if="timer_lg">Your Next Exercise Is</label>
                        <h4>{{plan[workout].items[exercise_item - 1].name}}</h4>
                        <!-- <h4 class="headline">{{plan[workout].items[exercise_item - 1].name}}</h4> -->
                        <div class="video_wpr">
                            <div class="video-overlay"></div>
                            <iframe :src="plan[workout].items[exercise_item - 1].link" class="loom-wrapper" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                        </div>
                        <div class="info_wpr" v-if="plan[workout].type == 'circuit'">
                            <h6><span>1 min</span> <span>30% 1RM</span></h6>
                        </div>
                        <div class="info_wpr" v-if="plan[workout].type == 'progression'">
                            <h6><span>3 sets</span> <span>8 reps</span> <span>10 kgs</span> <span>2 min rest</span></h6>
                        </div>
                    </div>
                </div>
            </Transition>
            <div class="other_counters" v-if="!toggle_box" :class="{'pb-4' : toggle_box}">
                <div class="counter"><h6>Total Time:</h6> <span>00</span> : <span>{{total_min_left < 10 ? `0${total_min_left}` : total_min_left}}</span> : <span>{{total_sec_left < 10 ? `0${total_sec_left}` : total_sec_left}}</span></div>
                <div class="counter" v-if="!toggle_box && timer_lg">
                    <h6>Rest!</h6>
                    <span>{{rest_min_left < 10 ? `0${rest_min_left}` : rest_min_left}}</span> : <span>{{rest_sec_left < 10 ? `0${rest_sec_left}` : rest_sec_left}}</span>
                    <button class="play_pause_btn" @click="stopStartRestCountdown"><i :class="`fas fa-${stoppedTimer ?  'play' : 'pause' }`"></i></button>
                </div>
                <div class="counter" v-if="plan[workout].type == 'circuit' && plan[workout].time && !timer_lg && !toggle_box">
                    <h6>Work!</h6>
                    <span>{{work_min_left < 10 ? `0${work_min_left}` : work_min_left}}</span> : <span>{{work_sec_left < 10 ? `0${work_sec_left}` : work_sec_left}}</span>
                    <button class="play_pause_btn" @click="stopStartWorkCountdown"><i :class="`fas fa-${stoppedTimer ?  'play' : 'pause' }`"></i></button>
                </div>
            </div>
            <Transition duration="550" name="nested">
                <div class="rest_alert" v-if="toggle_box && timer_lg">
                    <!-- <label for="text_reminder" class="switch_option capsule_btn border-0 m-0">
                        <input type="checkbox" id="text_reminder" :true-value="1" :false-value="0" v-model="rest_period" hidden>
                        <div><span></span></div>
                    </label> -->
                    <h5>Rest</h5>
                    <div class="score_circle">
                        <div class="inner_circle">
                            <div class="score" v-if="rest_min_left || rest_sec_left">
                                <div>{{rest_min_left < 10 ? `0${rest_min_left}` : rest_min_left}}:{{rest_sec_left < 10 ? `0${rest_sec_left}` : rest_sec_left}}</div>
                                <button class="play_pause_btn" @click="stopStartRestCountdown"><i :class="`fas fa-${stoppedTimer ?  'play' : 'pause' }`"></i></button>
                            </div>
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="160" height="160">
                            <circle cx="80" cy="80" r="77" stroke-linecap="round" fill="none" stroke-width="6px" :stroke="'var(--var-timer-color)'" :stroke-dasharray="484" :stroke-dashoffset="(484 / 60) * (rest_sec_left * 2)"/>
                        </svg>
                    </div>
                </div>
            </Transition>
            <Transition duration="550" name="nested">
                <div class="rounds" v-if="plan[workout].type == 'circuit' && toggle_box">
                    <span>Rounds</span>
                    <ul>
                        <li class="active">1</li>
                    </ul>
                </div>
            </Transition>
            <div class="exercises">
                <span v-if="toggle_box">Exercise {{ exercise_item }}</span>
                <ul>
                    <li :class="{active : (exercise_item - 1) >= 1, 'blink' : exercise_item == 1}"></li>
                    <li :class="{active : (exercise_item - 1) >= 2, 'blink' : exercise_item == 2}"></li>
                    <li :class="{active : (exercise_item - 1) >= 3, 'blink' : exercise_item == 3}"></li>
                </ul>
            </div>
            <!-- <div class="copyright"><router-link to="/">Terms</router-link><router-link to="/">Privacy</router-link> Powered By Onboard Me</div> -->
            <button type="button" v-if="!toggle_box" class="skip_btn" @click="skip">Skip</button>
        </div>

        <div class="rep_confirmation" :class="{active : rep_confirm && !timer_lg}">
            <div class="setting_wpr">
                <h3>What is your 1 rep max for this exercise?</h3>
                <div class="form_grp">
                    <div class="group_item">
                        <div class="field_wpr">
                            <input type="number" placeholder="80" v-model="max_reps">
                        </div>
                    </div>
                    <div class="group_item">
                        <div class="field_wpr">
                            <Multiselect v-model="weight_unit.value" v-bind="weight_unit"></Multiselect>
                        </div>
                    </div>
                </div>
                <div class="primary_btn" @click="rep_calculate">Calculate Loads</div>
            </div>
        </div>
    </div>
</template>
<script>
import Multiselect from '@vueform/multiselect';
import SwiperCore, { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper-bundle.min.css'
SwiperCore.use([Navigation, Pagination])

export default {
    name: 'PublicPage',
    props:{
        clr: Object,
        isWorkout: Boolean,
    },
    components:{
        Multiselect,
        Swiper,
        SwiperSlide,
    },
    data(){
        return{
            card_details: false,
            blockNumb: 0,
            exerciseNumb: 0,

            rep_confirm: false,
            nav_tab: 'plan',
            start_screen: false,
            total_min_left: 0,
            total_sec_left: 0,

            start_min_left: 0,
            start_sec_left: 0,

            work_timer: 'Start',
            work_min_left: 0,
            work_sec_left: 0,

            has_next_btn: false,
            workout_started: false,
            exercise: 1,
            toggle_box: false,
            timer_lg: false,
            rest_period: 1,
            incomplete: false,
            reminder_type: 1,
            reminder_day: 1,
            reminder_hr: 7,
            reminder_min: 30,
            reminder_clock: {
                mode: 'single',
                value: ['AM'],
                options: [
                    { value: 'AM', label: 'AM' },
                    { value: 'PM', label: 'PM' }
                ],
            },
            workout: 0,
            exercise_item: 1,
            active_round: 1,
            rest_min_left: 0,
            rest_sec_left: 0,
            workout_save: false,
            weeks:[
                {
                    timeline: 'May 1-7',
                    items:[
                        {
                            day: 'Mon',
                            workday: true
                        },
                        {
                            day: 'Tue',
                            workday: false
                        },
                        {
                            day: 'Wed',
                            workday: true
                        },
                        {
                            day: 'Thu',
                            workday: false
                        },
                        {
                            day: 'Fri',
                            workday: true
                        },
                        {
                            day: 'Sat',
                            workday: false
                        },
                        {
                            day: 'Sun',
                            workday: false
                        },
                    ]
                },
                {
                    timeline: 'May 8-14',
                    items:[
                        {
                            day: 'Mon',
                            workday: true
                        },
                        {
                            day: 'Tue',
                            workday: false
                        },
                        {
                            day: 'Wed',
                            workday: true
                        },
                        {
                            day: 'Thu',
                            workday: false
                        },
                        {
                            day: 'Fri',
                            workday: true
                        },
                        {
                            day: 'Sat',
                            workday: false
                        },
                        {
                            day: 'Sun',
                            workday: false
                        },
                    ]
                },
                {
                    timeline: 'May 15-21',
                    items:[
                        {
                            day: 'Mon',
                            workday: true
                        },
                        {
                            day: 'Tue',
                            workday: false
                        },
                        {
                            day: 'Wed',
                            workday: true
                        },
                        {
                            day: 'Thu',
                            workday: false
                        },
                        {
                            day: 'Fri',
                            workday: true
                        },
                        {
                            day: 'Sat',
                            workday: false
                        },
                        {
                            day: 'Sun',
                            workday: false
                        },
                    ]
                },
                {
                    timeline: 'May 22-28',
                    items:[
                        {
                            day: 'Mon',
                            workday: true
                        },
                        {
                            day: 'Tue',
                            workday: false
                        },
                        {
                            day: 'Wed',
                            workday: true
                        },
                        {
                            day: 'Thu',
                            workday: false
                        },
                        {
                            day: 'Fri',
                            workday: true
                        },
                        {
                            day: 'Sat',
                            workday: false
                        },
                        {
                            day: 'Sun',
                            workday: false
                        },
                    ]
                },
                {
                    timeline: 'May 29 - Jun 5',
                    items:[
                        {
                            day: 'Mon',
                            workday: true
                        },
                        {
                            day: 'Tue',
                            workday: false
                        },
                        {
                            day: 'Wed',
                            workday: true
                        },
                        {
                            day: 'Thu',
                            workday: false
                        },
                        {
                            day: 'Fri',
                            workday: true
                        },
                        {
                            day: 'Sat',
                            workday: false
                        },
                        {
                            day: 'Sun',
                            workday: false
                        },
                    ]
                }
            ],
            plan:[
                {
                    title: 'Warmup',
                    type: 'circuit',
                    time: true,
                    time_min: 1,
                    time_sec: 0,
                    items:[
                        {
                            name: 'Seated Shoulder Press',
                            link: 'https://storage.googleapis.com/thrivecoach/workout/shoulders/Smith%20Shoulder%20Press.mp4',
                            rest: 1,
                        },
                        {
                            name: 'Dead Hang',
                            link: 'https://storage.googleapis.com/thrivecoach/workout/stretching/Dead%20Hang%20Stretch.mp4',
                            rest: 1,
                        },
                        {
                            name: 'Thoracic Bridge',
                            link: 'https://storage.googleapis.com/thrivecoach/workout/stretching/Thoracic%20Bridge%20Stretching.mp4',
                            rest: 1,
                        },
                    ]
                },
                {
                    title: 'Strength',
                    type: 'progression',
                    items:[
                        {
                            name: 'Seated Shoulder Press',
                            link: 'https://storage.googleapis.com/thrivecoach/workout/shoulders/Smith%20Shoulder%20Press.mp4',
                            rest: 1,
                        },
                        {
                            name: 'Dead Hang',
                            link: 'https://storage.googleapis.com/thrivecoach/workout/stretching/Dead%20Hang%20Stretch.mp4',
                            rest: 1,
                        },
                        {
                            name: 'Thoracic Bridge',
                            link: 'https://storage.googleapis.com/thrivecoach/workout/stretching/Thoracic%20Bridge%20Stretching.mp4',
                            rest: 1,
                        },
                    ]
                },
                {
                    title: 'Recovery',
                    type: 'circuit',
                    time: true,
                    time_min: 1,
                    time_sec: 0,
                    items:[
                        {
                            name: 'Seated Shoulder Press',
                            link: 'https://storage.googleapis.com/thrivecoach/workout/shoulders/Smith%20Shoulder%20Press.mp4',
                            rest: 1,
                        },
                        {
                            name: 'Dead Hang',
                            link: 'https://storage.googleapis.com/thrivecoach/workout/stretching/Dead%20Hang%20Stretch.mp4',
                            rest: 1,
                        },
                        {
                            name: 'Thoracic Bridge',
                            link: 'https://storage.googleapis.com/thrivecoach/workout/stretching/Thoracic%20Bridge%20Stretching.mp4',
                            rest: 1,
                        },
                    ]
                }
            ],
            max_reps: '',
            calculate_load : '',
            weight_unit: {
                mode: 'single',
                value: 'lbs',
                options: [
                    { label: 'Pounds', value: 'lbs' },
                    { label: 'Kilograms', value: 'kgs' },
                    { label: 'Stones', value: 'stones' }
                ],
            },
            stoppedTimer:false,
            has_redirect: false,
            redirect_sec_left: 0,
            completedWorkout: 0,
        }
    },
    mounted(){
        const vm = this;

        vm.nav_tab = vm.isWorkout ? 'workouts' : 'plan';
    },
    watch:{
        start_screen(val){
            if(val){
                this.startCountdown(0, 15);
            }
        },
        exercise_item(val){
            if(val > 1){
                this.timer_lg = true;
            }
        },
        workout(val){
            if(val > 0){
                this.start_screen = true;
            }             
        },
        timer_lg(val){

            let scroll_content = document.querySelector('.workout_scroll');
            scroll_content.scrollTo(0, 0);

            if(val && this.workout <= 2 ){
                scroll_content.style.overflow = 'hidden';
                this.toggle_box = true;
                this.restCountdown(0, 30);
            }else{
                scroll_content.style.overflow = 'scroll';
                this.toggle_box = false;
            }
        },
        rest_period(val){
            if(!val){
                this.timer_lg = false;
            }
        },
        rep_confirm(val){
            let scroll_content = document.querySelector('.workout_scroll');
            // scroll_content.scrollTo(0, 0);

            if(val){
                scroll_content.style.overflow = 'hidden';
            }else{
                scroll_content.style.overflow = 'scroll';
            }
        },
        card_details(val){
            let scroll_content = document.querySelector('.workout_scroll');
            scroll_content.scrollTo(0, 0);

            if(val){
                scroll_content.style.overflow = 'hidden';
            }else{
                scroll_content.style.overflow = 'scroll';
            }
        },
        has_redirect(val){
            if(val){
                this.redirectCountdown(3);
            }
        }
    },
    methods:{
        startPlan(){
            this.nav_tab = 'workouts';
        },

        exerciseDetails(b, i){
            this.blockNumb = b;
            this.exerciseNumb = i;
            this.card_details = true;
        },

        rep_calculate(){
            const vm = this;
            if(vm.max_reps){
                vm.rep_confirm = false;
                vm.calculate_load = (vm.max_reps / 100) * 30;
                vm.workCountdown(0, 30);
                vm.has_next_btn = false;
            }else{
                vm.rep_confirm = true;
            }
        },

        startBtn(){
            this.start_screen = false;
            this.workout_started = true;
            this.nav_tab = 'details';

            if(this.workout == 0){
                this.totalCountdown(0, 0);
            }
        },

        startCountdown(minutes, seconds) {
            const vm = this;

            function tick() {
                if(!vm.stoppedTimer) {
                    vm.start_min_left = minutes;
                    vm.start_sec_left = seconds;

                    seconds--;
                    if (seconds >= 0) {
                        setTimeout(tick, 1000);
                    } else {
                        if (minutes >= 1) {
                            setTimeout(function () {
                                vm.startCountdown(minutes - 1, 59);
                            }, 1000);
                        }
                    }
                }
            }
            tick();
        },
        stopStartCountdown() {
            this.stoppedTimer = !this.stoppedTimer;
            if(!this.stoppedTimer) {
                this.startCountdown(this.start_min_left, this.start_sec_left)
            }
        },

        totalCountdown(minutes, seconds) {
            const vm = this;

            function tick() {
                vm.total_min_left = minutes;
                vm.total_sec_left = seconds;

                seconds++;

                if (seconds <= 59) {
                    setTimeout(tick, 1000);
                }else {
                    if (minutes >= 0) {
                        setTimeout(function () {
                            vm.totalCountdown(minutes + 1, 0);
                        }, 1000);
                    }
                }
            }
            tick();
        },

        restCountdown(minutes, seconds) {
            const vm = this;

            function tick() {
                if(!vm.stoppedTimer) {
                    vm.rest_min_left = minutes;
                    vm.rest_sec_left = seconds;

                    seconds--;
                    if (seconds >= 0) {
                        setTimeout(tick, 1000);
                    } else {
                        if (minutes >= 1) {
                            setTimeout(function () {
                                vm.restCountdown(minutes - 1, 59);
                            }, 1000);
                        }else{
                            vm.timer_lg = false;
                        }
                    }
                }
            }
            tick();
        },
        stopStartRestCountdown() {
            this.stoppedTimer = !this.stoppedTimer;
            if(!this.stoppedTimer) {
                this.restCountdown(this.rest_min_left, this.rest_sec_left)
            }
        },

        workCountdown(minutes, seconds) {
            const vm = this;

            function tick() {
                if(!vm.stoppedTimer) {
                    vm.work_min_left = minutes;
                    vm.work_sec_left = seconds;

                    seconds--;
                    if (seconds >= 0) {
                        setTimeout(tick, 1000);
                    } else {
                        if (minutes >= 1) {
                            setTimeout(function () {
                                vm.workCountdown(minutes - 1, 59);
                            }, 1000);
                        }else{
                            vm.nextExercise();
                            vm.has_next_btn = true;
                        }
                    }
                }
            }
            tick();
        },
        stopStartWorkCountdown() {
            this.stoppedTimer = !this.stoppedTimer;
            if(!this.stoppedTimer) {
                this.workCountdown(this.work_min_left, this.work_sec_left)
            }
        },

        goTo (refName) {
            const vm = this;
            let scroll_content = document.querySelector('.workout_scroll');
            let element = vm.$refs[refName];
            let top = element.offsetTop;

            setTimeout(function() {
                scroll_content.scrollTo(0, top);
                // document.querySelector('.preview_content .content_area').scrollTo(0, top);
            }, 100);
        },

        redirectCountdown(seconds) {
            const vm = this;

            function tick() {
                vm.redirect_sec_left = seconds;

                seconds--;
                if (seconds >= 0) {
                    setTimeout(tick, 1000);
                } else {
                    vm.has_redirect = false;
                    vm.completedWorkout++ ;
                    vm.nav_tab = 'plan';
                    vm.workout = 0;
                    vm.exercise_item = 1;
                }
            }
            tick();
        },

        nextExercise(){
            if(this.exercise_item <= 2 ){
                this.exercise_item++;
                this.max_reps = '';
            }
        },
        nextWorkout(){
            if(this.workout < this.plan.length - 1){
                this.workout++;
                this.exercise_item = 1;
            }else{
                this.workout_started = false;
                this.nav_tab = 'complete'
            }
        },
        skip(){
            let scroll_content = document.querySelector('.workout_scroll');

            if(this.exercise_item < 3){
                this.exercise_item++;
            }else if(this.workout < 2){
                this.workout++;
                this.exercise_item = 1;
            }else if(this.workout == 2 && this.exercise_item == 3){
                this.workout_started = false;
                this.nav_tab = 'complete';
                this.restCountdown(0, 0);
                scroll_content.style.overflow = 'scroll';
            }
        },
        foo(){
            this.rep_confirm = true;
        }
    }
}
</script>

<style scoped>
    .dashboard_content{
        background: #fff;
    }
    .tab .dashboard_content{
        margin: 0;
    }
    .cell .dashboard_content{
        margin: 0;
        padding: 0 15px;
    }
    .logo_img {
        max-width: 100%;
        height: auto;
        max-height: 25px;
    }
    .client_card {
        width: 100%;
        border: 0;
        border-bottom: 1px solid #e9e9e9;
    }
    .client_card .card_header .user_box > img{
        width: 20px;
        height: 20px;
    }
    .client_card .card_header .user_box h5{
        font-size: 11px;
        line-height: 14px;
    }
    .cell .client_card .card_header {
        padding: 15px 20px;
        background: #fff;
    }
    .dashboard_footer{
        width: 100%;
        text-align: center ;
    }
    .dashboard_container{
        flex: 0 1 800px;
        margin: 40px auto;
    }

    .back_btn{
        width: 20px;
        height: 20px;
        color: #5a5a5a;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .card_details{
        position: absolute;
        background: #fff;
        top: 100%;
        left: 0;
        right: 0;
        max-height: 0;
        overflow: hidden;
        z-index: 4;
        display: flex;
        flex-direction: column;
        align-items: center;
        transition: all 0.5s ease-in-out;
    }
    .card_details.active{
        max-height: 610px;
        top: 0;
        overflow-Y: scroll;
    }
    .card_details::-webkit-scrollbar{
        display: none;
    }
    .card_details .deatils_header{
        width: 100%;
        padding: 15px;
        border-bottom: 1px solid #e9e9e9;
        background: #fff;
        position: sticky;
        top: 0;
        left: 0;
        z-index: 2;
        display: flex;
        align-items: center;
        gap: 20px;
    }
    .card_details .exercise_details{
        padding: 30px 15px;
    }

    .rep_confirmation{
        text-align: center;
        position: sticky;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        max-height: 0;
        height: auto;
        overflow: hidden;
        z-index: 4;
        background: rgba(0,0,0,0.2);
        display: flex;
        flex-direction: column;
        align-items: center;
        transition: all 0.5s ease-in-out;
    }
    .rep_confirmation.active{
        box-shadow: 0 -3px 25px rgba(0,0,0,0.15);
        max-height: 610px;
        height: auto;
        overflow: visible;
    }
    .rep_confirmation .setting_wpr{
        width: 100%;
        padding: 25px;
        background: #fff;
        border-radius: 6px 6px 0 0;
    }
    .rep_confirmation .setting_wpr h3{
        font-size: 15px;
        line-height: 22px;
        color: #121525;
        font-weight: 500;
        text-align:left;
        padding-bottom: 5px;
    }
    .rep_confirmation .field_wpr input, .rep_confirmation .form_grp .multiselect{
        height: 40px;
        min-height: 40px;
    }
    .rep_confirmation .form_grp .multiselect :deep(.multiselect-single-label-text){
        font-size: 13px;
    }
    .rep_confirmation .setting_wpr .primary_btn{
        margin: 10px 0;
        cursor: pointer;
        font-size: 15px;
        line-height: 20px;
        text-transform: uppercase;
        height: 45px;
        letter-spacing: 0.5px;
    }

    /*------- Plan --------*/

    .exercise_list{
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 50px;
    }
    .exercise_item .ex_title{
        font-size: 15px;
        line-height: 20px;
        color: #121525;
        font-weight: 500;
        margin-bottom: 10px;
    }
    .quick_guide{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .quick_guide ul{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 30px 0;
    }
    .quick_guide ul li{
        padding: 0 25px;
        border-right: 1px solid #d9d9d9;
    }
    .quick_guide ul li:last-child{
        border: 0;
    }
    .quick_guide ul li h5{
        font-size: 15px;
        line-height: 20px;
        color: #121525;
        font-weight: 500;
        margin-bottom: 5px;
        text-align:center;
    }
    .quick_guide ul li h6{
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 500;
        text-align:center;
    }
    .start_btn{
        min-width: 200px;
        background: var(--var-btn-color);
        color: var(--var-btn-txt-color);
        height: 45px;
        border-radius: 5px;
        padding: 10px 15px;
        font-size: 15px;
        line-height: 21px;
        cursor: pointer;
        padding: 0 15px;
        margin: 10px auto;
    }
    .week_list{
        padding: 20px 0;
    }
    .week_list .day_guide{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 15px;
        padding: 0 0 20px 0; 
    }
    .week_list .day_guide label{
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        display: flex;
        align-items: center;
        gap: 7px;
    }
    .week_list ul{
        position: relative;
    }
    .week_list ul:before{
        position: absolute;
        content: '';
        left: 11px;
        top: 60px;
        bottom: 60px;
        border-left: 1px solid #d9d9d9;
    }
    .week_list ul li{
        padding: 10px 0;
        display: flex;
        align-items: center;
        position: relative;
    }
    .week_list ul li:before{
        position: absolute;
        content: '';
        right: 1px;
        left: 1px;
        top: calc(50% - 1px);
        border-bottom: 1px solid #d9d9d9;
    }
    .week_list ul li .bullet_icon{
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: #e9e9e9;
        border: 1px solid #d9d9d9;
        font-size: 10px;
        line-height: 12px;
        font-weight: 500;
        color: #121525;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 1;
    }
    .exercise_item .exercise_info {
        min-width: 280px;
        margin-left: auto;
        padding: 15px;
        border-radius: 10px;
        background: var(--var-block-bg-color);
        position: relative;
        z-index: 1;
    }
    .exercise_item ul li.completed{
        pointer-events: none;
    }
    .exercise_item ul li.completed .exercise_info{
        filter: grayscale(1);
    }
    .exercise_item .exercise_info h4{
        font-size: 13px;
        line-height: 18px;
        color: #121525;
        font-weight: 500;
        display: flex;
        align-items: center;
        gap: 10px;
    }
    .exercise_item .exercise_info h4 span{
        margin-left: auto;
        color: #5a5a5a;
        font-size: 11px;
        font-weight: 400;
    }
    .week_days_list{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 3px;
        margin-top: 10px;
        position: relative;
    }
    .week_days_list label{
        flex: 1 1 30px;
    }
    .week_days_list label .item_wpr{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        cursor: pointer;
        margin: 0;
        padding: 6px 4px;
        background: transparent;
        border: 1px solid transparent;
        border-radius: 3px;
        transition: all 0.3s ease-in-out;
    }
    .week_days_list label .item_wpr.present_day{
        border: 1px solid #bebebe;
    }
    .week_days_list label span{
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 400;
    }
    .week_days_list label .day_stat, .week_list .day_guide label span{
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background: #c1c1c1;
        position: relative;
        margin-top: 7px;
    }
    .week_days_list label .day_stat.active, .week_list .day_guide label span.active{
        background: var(--var-accent-color);
    }
    .exercise_item ul li.completed .week_days_list:before{
        position: absolute;
        content: '';
        left: 15px;
        right: 15px;
        border-top: 1px solid #bebebe;
    }
    .exercise_item ul li.completed .week_days_list label .day_stat.active:before{
        position: absolute;
        content: '';
        left: 5px;
        bottom: 100%;
        height: 14px;
        border-left: 1px solid #bebebe;
    }
    .exercise_item ul li.completed .week_days_list label .day_stat.active:last-of-type:after{
        position: absolute;
        content: '';
        left: 5px;
        top: -10px;
        width: 250px;
        border-top: 1px solid #bebebe;
    }
    .timer{
        text-align: center;
        position: sticky;
        bottom: 0;
        z-index: 4;
        background: #fff;
        border-radius: 10px 10px 0 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-height: 0;
        height: 100%;
        overflow: hidden;
        transition: all 0.5s ease-in-out;
    }
    .timer.active{
        padding: 15px;
        box-shadow: 0 -3px 25px rgba(0,0,0,0.15);
        overflow: visible;
        max-height: 150px;
        height: auto;
        bottom: 0;
    }
    .timer.active.lg{
        max-height: 610px;
        height: 100%;
        overflow-y: scroll;
    }
    .timer.active.lg::-webkit-scrollbar{
        display: none;
    }
    .timer .alert{
        font-size: 13px;
        line-height: 20px;
        font-weight: 500;
        color: #5a5a5a;
        padding-bottom: 10px;
        border-bottom: 1px solid #e9e9e9;
        width: calc(100% + 30px);
        margin: -5px -15px 15px -15px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .timer .alert i{
        font-size: 16px;
        margin-left: 15px;
        color: #999;
        animation: mover2 0.5s infinite alternate;
    }
    .toggle_box{
        font-size: 16px;
        color: #777;
        position: absolute;
        top: 10px;
        left: 15px;
        cursor: pointer;
    }
    .timer .counter{
        font-size: 18px;
        line-height: 25px;
        font-weight: 500;
        color: #5a5a5a;
        position: relative;
    }
    .timer .counter h6{
        font-size: 11px;
        line-height: 14px;
        color: #121525;
        font-weight: 400;
        padding-bottom: 3px;
    }
    .timer .counter h6 button{
        font-size: 9px;
        line-height: 12px;
        border-radius: 3px;
        padding: 2px 5px;
        color: var(--var-btn-txt-color);
        background: var(--var-btn-color);
        margin-right: 5px;
    }
    .timer .counter span{
        color: var(--var-timer-color);
    }
    .timer .other_counters{
        display: flex;
        padding: 0 0 5px;
    }
    .timer .other_counters .counter{
        padding: 0 20px;
    }
    .timer .other_counters .counter:not(:first-of-type){
        border-left: 1px solid #d9d9d9;
    }
    .timer .success{
        color: var(--var-accent-color);
        font-size: 30px;
        padding-bottom: 10px;
    }

    .timer .copyright{
        font-size: 11px;
        line-height: 16px;
        font-weight: 400;
        color: #999;
        margin-top: auto;
    }
    .rest_alert{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 20px 10px 20px;
        position: relative;
    }
    .rest_alert label.capsule_btn {
        position: absolute;
        right: 0;
        top: 20px;
    }
    .rest_alert .score_circle{
        width: 160px;
        height: 160px;
        padding: 6px;
    }
    .rest_alert .score_circle svg{
        pointer-events: none;
    }
    .rest_alert .score_circle svg circle{
        transition: all 0.3s ease-in-out;
    }
    .rest_alert .score_circle .inner_circle{
        background: #fff;
    }
    .rest_alert .score_circle .inner_circle .score {
        font-size: 22px;
        line-height: 28px;
        font-weight: 300;
        color: #121525;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
    }
    .rest_alert h5{
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        color: #121525;
        margin-bottom: 10px;
    }
    /* .rest_alert .score_circle .inner_circle .score h5{
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        color: #121525;
        position: absolute;
        bottom: 100%;
        left: 50%;
        margin-bottom: 10px;
        transform: translateX(-50%);
    } */
    .play_pause_btn{
        font-size: 10px;
        color: #757575;
        width: 26px;
        height: 26px;
        border-radius: 50%;
        background: #ebebeb;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 100%;
        left: calc(50% - 10px);
        margin-top: 10px;
        cursor: pointer;
    }
    .play_pause_btn i.fa-play{
        margin-left: 3px;
    }
    .timer .counter .play_pause_btn{
        left: 100%;
        top: auto;
        bottom: 3px;
    }
    .rest_alert .score_circle .inner_circle .score.go{
        width: 50px;
        height: 50px;
        animation: clr_anim 1s infinite  alternate;
    }
    .rest_alert .score_circle .inner_circle .score .hand_icon{
        position: absolute;
        left: calc(50% - 10px);
        top: 100%;
        margin-top: 5px;
        font-size: 25px;
        color: #2c3e50;
        animation: mover 1s infinite  alternate;
    }

    .timer .rounds{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 15px;
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        color: #5a5a5a;
        padding: 7px 0;
    }
    .timer .rounds ul{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 7px;
    }
    .timer .rounds ul li{
        width: 20px;
        height: 20px;
        border-radius: 50%;
        font-size: 10px;
        line-height: 12px;
        font-weight: 400;
        color: #fff;
        background: #888;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .timer .rounds ul li.active{
        background: var(--var-timer-color);
    }

    .timer .exercises{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 15px;
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        color: #5a5a5a;
        padding: 10px 0;
    }
    .timer .exercises ul{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }
    .timer .exercises ul li{
        width: 20px;
        height: 4px;
        border-radius: 2px;
        background: #999;
    }
    .timer .exercises ul li.blink{
        animation: clr_anim2 0.5s infinite alternate;
    }
    .timer .exercises ul li.active{
        background: var(--var-timer-color);
    }
    .timer .exercise_details{
        width: 100%;
        text-align: left;
        padding-bottom: 10px;
    }
    .timer .skip_btn{
        font-size: 11px;
        line-height: 14px;
        padding: 4px 10px;
        font-weight: 500;
        color: #121525;
        position: absolute;
        right: 15px;
        bottom: 5px;
        cursor: pointer;
    }

    /* ------- workout start screen ------------ */

    .start_screen{
        justify-content: center;
        z-index: 5;
    }
    .block_stat{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1px;
        margin-bottom: 50px;
    }
    .block_stat li{
        background: #dbdbdb;
        padding: 8px 20px;
        font-size: 11px;
        line-height: 16px;
        font-weight: 500;
        color: #121525;
        border-radius: 2px;
        transition: all 0.3s ease-in-out;
    }
    .block_stat li.active{
        background: #2f7eed;
        color: #fff;
    }
    .block_stat li.blink{
        animation: clr_anim 1s infinite alternate;
    }
    .timer .welcome_title{
        font-size: 20px;
        line-height: 30px;
        max-width: 270px;
    }
    .welcome_title .title_sm{
        font-size: 15px;
        line-height: 22px;
    }
    .timer .block_note{
        font-size: 13px;
        line-height: 20px;
        font-weight: 500;
        color: #121525;
        max-width: 300px;
        margin-top: 10px;
    }
    .final_note {
        background: #f5f5f6;
        border-radius: 5px;
        padding: 15px;
        color: #444;
        font-size: 11px;
        line-height: 16px;
        font-weight: 400;
        margin-top: 20px;
        max-width: 300px;
    }

    /*------- Workout --------*/
    .plan_wrapper{
        padding: 20px 0 40px 0;
    }
    .plan_wrapper .swiper-wrapper{
        overflow: hidden;
    }
    .plan_wrapper .swiper-slide{
        width: 100% !important;
    }
    .plan_wrapper :deep(.swiper-pagination-bullet-active){
        background: var(--var-accent-color);
    }
    .plan_wrapper :deep(.swiper-button-next), .plan_wrapper :deep(.swiper-button-prev){
        top: 20px;
        width: 20px;
        height: 20px;
        margin: 0;
    }
    .plan_wrapper :deep(.swiper-button-next.swiper-button-disabled), .plan_wrapper :deep(.swiper-button-prev.swiper-button-disabled){
        opacity: 0;
    }
    .plan_wrapper :deep(.swiper-button-next){
        right: 0;
    }
    .plan_wrapper :deep(.swiper-button-next:after){
        content: '\f30b';
        position: absolute;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        font-size: 18px;
        color: var(--var-accent-color);
    }
    .plan_wrapper :deep(.swiper-button-prev){
        left: 0;
    }
    .plan_wrapper :deep(.swiper-button-prev:after){
        content: '\f30a';
        position: absolute;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        font-size: 18px;
        color: var(--var-accent-color);
    }
    .block_title{
        font-size: 15px;
        line-height: 20px;
        color: #121525;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 10px;
    }
    .exercise_item .plan_list{
        padding: 15px;
        border-radius: 10px;
        background: var(--var-block-bg-color);
        position: relative;
        z-index: 1;
    }
    .exercise_item .plan_list .block_type{
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 400;
        display: flex;
        justify-content: flex-end;
        padding-bottom: 5px;
    }
    .exercise_item .plan_list .plan_info{
        font-size: 11px;
        line-height: 14px;
        color: #121525;
        font-weight: 500;
        display: flex;
        align-items: center;
    }
    .exercise_item .plan_list h4 span{
        margin-left: auto;
        color: #5a5a5a;
        font-size: 11px;
        font-weight: 400;
    }
    .exercise_item .plan_list ul{
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding: 15px 0;
    }
    .exercise_item .plan_list ul li{
        background: #fff;
        border-radius: 6px;
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
        cursor: pointer;
    }
    .exercise_item .plan_list ul li .video_area{
        width: 33%;
    }
    .exercise_item .plan_list ul li .info_wpr{
        width: calc(67% - 12px);
        padding: 10px 0;
    }
    .exercise_item .plan_list ul li .info_wpr h4{
        font-size: 13px;
        line-height: 18px;
        color: #121525;
        font-weight: 500;
        margin-bottom: 7px;
    }
    .exercise_item .plan_list ul li .info_wpr h6{
        font-size: 11px;
        line-height: 15px;
        color: #5a5a5a;
        font-weight: 400;
        display: flex;
        flex-wrap: wrap;
        margin: 0 -5px;
    }
    .exercise_item .plan_list ul li .info_wpr h6 span{
        padding: 0 5px;
    }
    .exercise_item .plan_list ul li .info_wpr h6 span:not(:last-child){
        border-right: 1px solid #d9d9d9;
    }
    .video_wpr{
        width: 100%;
        position: relative;
        padding-bottom: 56.25%;
        margin: 0;
        border-radius: 6px 0 0 6px;
        overflow: hidden;
    }
    .video_wpr iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }


    /*------- details --------*/
    .exercise_details .ex_title{
        font-size: 18px;
        line-height: 25px;
        color: #121525;
        font-weight: 500;
        margin-bottom: 10px;
        text-align: center;
        padding: 0 20px;
        position: relative;
    }
    .exercise_details .ex_title .back_btn{
        font-size: 15px;
        color: #999;
        position: absolute;
        left: 0;
        top: 5px;
        cursor: pointer;
    }
    .exercise_details .info_wpr{
        display: flex;
        align-items: center;
        justify-content:space-between
    }
    .exercise_details .info_wpr h6{
        font-size: 11px;
        line-height: 14px;
        color: #121525;
        font-weight: 500;
        padding: 10px 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
    .exercise_details .info_wpr h6 span{
        padding: 4px 10px;
        background: #e9e9e9;
        border: 1px solid #f5f5f5;
        border-radius: 2px;
    }

    .exercise_details .instruction, .exercise_list .instruction{
        padding: 30px 0 0 15px;
    }
    .exercise_details .instruction h4.headline, .exercise_list .instruction h4.headline{
        font-size: 16px;
        line-height: 23px;
        color: #121525;
        font-weight: 500;
        padding-bottom: 12px;
        position: relative;
        display: flex;
    }
    .exercise_details .instruction h4:before, .exercise_list .instruction h4:before{
        position: absolute;
        height: 20px;
        content: '';
        left: -15px;
        top: 0;
        border-left: 3px solid var(--var-accent-color);
    }

    .exercise_details button.calc_btn{
        height: 24px;
        padding: 0 10px;
        background: #f5f5f5;
        font-size: 11px;
        line-height: 14px;
        border-radius: 3px;
        flex-grow: 0;
        margin-left: auto;
    }
    .exercise_details button.calc_btn.blink{
        animation: clr_anim 0.5s infinite alternate;
    }
    .exercise_details .instruction h4{
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        color: #121525;
        text-align:center;
        padding-bottom: 10px;
    }
    .exercise_details .instruction label{
        text-align: center;
        font-size: 13px;
        line-height: 16px;
        font-weight: 400;
        color: #5a5a5a;
        display: block;
        padding-bottom: 5px;
    }
    .exercise_details .instruction h5{
        font-size: 13px;
        line-height: 16px;
        font-weight: 400;
        color: #5a5a5a;
        padding: 10px 0 15px 0;
    }
    .exercise_details .instruction p, .exercise_list .instruction p{
        font-size: 13px;
        line-height: 20px;
        color: #5a5a5a;
        font-weight: 400;
    }
    .exercise_details .instruction .video_wpr{
        border-radius: 6px;
    }

    .activity{
        margin: 30px 0;
        padding-top: 15px;
    }
    .activity table{
        width: 100%;
        border-spacing: 8px;
        background: var(--var-block-bg-color);
        border-radius: 5px;
        padding: 0 5px 5px 5px;
    }
    .activity table th, .activity table td{
        padding: 10px 5px;
        border-radius: 3px;
        text-align: center;
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        color: #121525;
    }
    .activity table th{
        width: 23%;
    }
    .activity table td{
        width: 23%;
        background: #fff;
        color: #777;
    }
    .activity table th.set, .activity table td.set{
        width: 40px;
        background: var(--var-block-bg-color);
    }
    .activity table td input{
        width: 100%;
        text-align: center;
        font-size: 11px;
        background: #fff;
    }
    .activity table td input[type=number].no_spinner{
        width: 25px;
    }
    .activity table td input[type=number].no_spinner::-webkit-outer-spin-button, 
    .activity table td input[type=number].no_spinner::-webkit-inner-spin-button{
        -webkit-appearance: none;
    }
    .exercise_details .action_wpr{
        gap: 15px;
        position: relative;
        margin: 30px 0 20px 0;
    }
    .exercise_details button{
        flex-grow: 1;
        height: 45px;
        border-radius: 5px;
        letter-spacing: 0.3px;
        font-size: 13px;
        line-height: 16px;
        cursor: pointer;
    }
    .exercise_details .incomplete_btn{
        border: 1px solid #dbdde1;
        background: #fff;
        color: #2c3e50;
    }
    .exercise_details .complete_btn{
        border: 1px solid var(--var-btn-color);
        background: var(--var-btn-color);
        color: var(--var-btn-txt-color);
    }
    .incomplete_sec{
        position: absolute;
        width: 300px;
        padding: 5px 15px;
        bottom: 100%;
        left: 0;
        margin-bottom: 10px;
        background: #fff;
        border: 1px solid #e9e9e9;
        border-radius: 8px;
        box-shadow: 5px 50px 50px rgba(0,0,0,.1);
        text-align: center;
        z-index: 4;
    }
    .incomplete_sec:after {
        position: absolute;
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 262.83 148.44'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;stroke:%23c7c6c6;stroke-miterlimit:10;stroke-width:7px;%7D%3C/style%3E%3C/defs%3E%3Cpolyline class='cls-1' points='2.58 2.36 131.41 143.25 260.24 2.36'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        width: 20px;
        height: 13px;
        left: 50px;
        top: calc(100% - 1px);
    }
    .incomplete_sec .action_wpr{
        gap: 10px;
        justify-content: flex-end;
        margin: 5px 0 15px 0;
    }
    .incomplete_sec button{
        flex-grow: 0;
        height: 30px;
        border-radius: 3px;
        letter-spacing: 0.3px;
        font-size: 13px;
        line-height: 16px;
        cursor: pointer;
        padding: 0 15px;
    }
    .incomplete_sec .cancel_rsn{
        border: 1px solid #dbdde1;
        background: #fff;
        color: #2c3e50;
    }
    .incomplete_sec .save_rsn{
        border: 1px solid #2c3e50;
        background: #2c3e50;
        color: #fff;
    }


    /*-------- Complete -------*/
    .workout_completion{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .finish_timer{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 15px;
    }
    .finish_timer h2{
        font-size: 20px;
        line-height: 28px;
        font-weight: 500;
        color: #121525;
        padding: 10px 0;
        border-bottom: 1px solid #d9d9d9;
    }
    .total_timer{
        display: flex;
        gap: 10px;
        font-size: 22px;
        line-height: 28px;
        color: #121525;
        font-weight: 500;
        padding: 10px 0;
    }
    .total_timer label{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .total_timer label span{
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        color: #5a5a5a;
    }
    .ratings ul{
        display: flex;
        flex-direction: column;
        padding-bottom: 15px;
    }
    .ratings ul li{
        flex-grow: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 15px;
        padding: 5px 0;
        font-size: 13px;
        line-height: 18px;
        font-weight: 500;
        color: #5a5a5a;
    }
    .ratings ul li span{
        display: flex;
        flex-direction: row-reverse;
        gap: 5px;
        color: var(--var-accent-color);
    }

    /* ----- for ratings ---------- */

    .ratings ul li span input {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 15px;
        height: 15px;
        cursor: pointer;
        appearance: none;
        -webkit-appearance: none;
    }
    .ratings ul li span input::before {
        content: '\f005';
        position: absolute;
        font-family: "Font Awesome 5 Free";
        font-weight: 500;
        font-size: 13px;
        color: var(--var-accent-color);
        transition: 0.5s;
    }
    .ratings input:hover ~ input::before,
    .ratings input:hover::before,
    .ratings input:checked ~ input::before,
    .ratings input:checked::before {
        font-weight: 900;
    }
    .workout_completion .setting_wpr{
        max-width: 300px;
        width: 100%;
    }
    .workout_completion .field_wpr textarea{
        height: 100px;
    }


    /*-------- Complete -------*/

    .workout_logged{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .workout_logged .success{
        color: var(--var-accent-color);
        font-size: 30px;
        padding: 15px 0 30px 0;
    }
    .workout_logged h4{
        font-size: 18px;
        line-height: 25px;
        color: #121525;
        font-weight: 500;
        padding-bottom: 5px;
    }
    .workout_logged p{
        font-size: 13px;
        line-height: 20px;
        color: #5a5a5a;
        font-weight: 400;
    }
    .workout_name{
        padding: 15px 20px;
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        color: #121525;
        margin: 30px 0 15px;
        background: linear-gradient(to right, rgba(0,0,0,0), rgba(0,0,0,0.2), rgba(0,0,0,0));
        max-width: 250px;
        width: 100%;
        text-align: center;
    }
    .reminder{
        padding: 15px 0;
    }
    .reminder_day{
        padding: 15px 0;
    }
    .reminder_day label{
        display: flex;
        align-items: flex-start;
        gap: 10px;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color: #121525;
        padding: 5px 0;
        cursor: pointer;
    }
    .reminder_day label span{
        width: 14px;
        height: 14px;
        border-radius: 50%;
        border: 1px solid var(--var-accent-color);
        position: relative;
        margin-top: 3px;
    }
    .reminder_day label span:after{
        content: '';
        position: absolute;
        left: 2px;
        top: 2px;
        right: 2px;
        bottom: 2px;
        background-color: var(--var-accent-color);
        border-radius: 50%;
        transform: scale(0);
        transition: all 0.3s ease-in-out;
    }
    .reminder_day label input[type=radio]:checked ~ span:after{
        transform: scale(1);
    }
    .time_line{
        max-width: 250px;
        width: 100%;
    }
    .workout_logged .form_grp :deep(.multiselect), .workout_logged .form_grp .field_wpr input{
        height: 35px;
        min-height: 35px;
    }
    .workout_logged .form_grp :deep(.multiselect .multiselect-dropdown){
        min-width: 75px;
    }
    .workout_logged :deep(.multiselect-single-label){
        padding: 0 20px 0 15px;
    }
    .workout_logged :deep(.multiselect-caret){
        margin-right: 10px;
    }
    .workout_logged :deep(.multiselect-single-label-text){
        font-size: 11px;
    }

    @keyframes clr_anim{
        0% {
            background: #f5f5f5;
        }
        100% {
            background: #dddddd;
         }
    }
    @keyframes clr_anim2{
        0% {
            background: #ddd;
        }
        100% {
            background: #999;
         }
    }
    @keyframes mover {
        0% { 
            transform: translateY(0);
        }
        100% { 
            transform: translateY(-10px);
         }
    }
    @keyframes mover2 {
        0% { 
            transform: translateX(0);
        }
        100% { 
            transform: translateX(-7px);
         }
    }
</style>
